import { DeleteTwoTone, PlusCircleTwoTone, PlusSquareTwoTone } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Checkbox, Col, Divider, List, Modal, Row, Switch, message } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionLink, Button, DatePicker, SearchInput } from '../../../components';
import AutoComplete from '../../../components/AutoComplete';
import { ROLES } from '../../../constants';
import { getProfile } from '../../../utils/auth';
import { formatDateDisplay } from '../../../utils/formatters';
import { withRouter } from '../../../utils/wrappers';
import {
  getClientDetail,
  getPricingGroupZones,
  getPricingGroupsWithEffectiveDate
} from '../client-details.action';
import { clientService } from '../client-details.service';

const { confirm } = Modal;

const StyledModal = styled.div`
  .label {
    font-family: AvenirLTStdHeavy;
    font-size: 16px;
    color: #707070;
    display: block;
    font-style: normal;
  }
  .value {
    font-family: AvenirLTStdBook;
    font-size: 16px;
    color: #000000;
  }
  .info {
    font-family: AvenirLTStdBook;
    color: #707070;
  }
`;

const StyledHeader = styled.h5`
  display: flex;
  align-items: center;
`;

const StyledDatePicker = styled(DatePicker)`
  padding: 0px;
  .ant-picker-input > input {
    font-family: AvenirLTStdBook;
    color: #000000;
    font-size: 16px;
  }
`;

const initialState = {
  name: undefined,
  startDate: undefined,
  endDate: undefined,
  expiringDate: undefined,
  isAllZones: false,
  origin: undefined,
  destination: undefined,
  origins: [],
  destinations: [],
  rules: []
};

const MappedCustomPricingGroups = ({
  params,
  mappedCustomPricingGroups = [],
  // eslint-disable-next-line no-unused-vars
  hasPostpaidOnly = false
}) => {
  const dispatch = useDispatch();
  const { clientID } = params;
  const { roles } = getProfile();
  const isAdmin = roles.includes(ROLES.FINANCE) || roles.includes(ROLES.KYC);
  const [loading, setLoading] = useState(false);
  const [mappingVisible, setMappingVisible] = useState(false);
  const [expiringVisible, setExpiringVisible] = useState(false);
  const [pricingGroups, setPricingGroups] = useState([]);
  const [expiringPricingGroups, setExpiringPricingGroups] = useState([]);
  const [viewAllPricingGroups, setViewAllPricingGroups] = useState(false);
  const [disabledDate, setDisabledDate] = useState(moment().subtract(1, 'days'));
  const [
    {
      name,
      startDate,
      endDate,
      expiringDate,
      isAllZones,
      origin,
      destination,
      origins,
      destinations,
      rules
    },
    setState
  ] = useState(initialState);

  const { data, loading: pricingGroupLoading } = useSelector(
    (state) => state.clientDetails.pricingGroups
  );

  const {
    origins: pricingGroupOrigins,
    destinations: pricingGroupDestinations,
    loading: pricingGroupZonesLoading
  } = useSelector((state) => state.clientDetails.pricingGroupZones);

  const handleValue = (name) => (value) => {
    if (name === 'name') {
      const data = JSON.parse(value);
      const startDate = data.effective_date ? moment.utc(data.effective_date) : moment();

      setState((prevState) => ({
        ...prevState,
        name: data.name,
        startDate
      }));

      setDisabledDate(startDate);
      dispatch(getPricingGroupZones(data.name));
      return;
    }

    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleDisabledDate = (startDate, endDate) => (current) => {
    let startCheck = true;
    if (startDate) {
      startCheck = current && current < startDate;
    }

    let endCheck = true;
    if (endDate) {
      endCheck = current && current > endDate;
    }

    return (startDate && startCheck) || (endDate && endCheck);
  };

  const handleAddRule = () => {
    if (!origin || !destination) {
      return;
    }

    const key = `${origin}_${destination}`;
    const rule = { key, origin, destination };

    if (rules.some((rule) => rule.key === key)) {
      return;
    }

    setState((prevState) => ({ ...prevState, rules: [...rules, rule] }));
    setState((prevState) => ({ ...prevState, origin: undefined, destination: null }));
  };

  const handleDeleteRule = (key) => {
    const newRules = rules.filter((rule) => rule.key !== key);

    setState((prevState) => ({ ...prevState, rules: [...newRules] }));
  };

  const handleCheckbox = (pricingGroup) => (e) => {
    const { checked } = e.target;

    if (!checked) {
      const newPricingGroup = expiringPricingGroups.filter((pg) => pg.id !== pricingGroup.id);
      setExpiringPricingGroups(newPricingGroup);
      return;
    }

    setExpiringPricingGroups([...expiringPricingGroups, pricingGroup]);
  };

  const handleMapping = () => {
    const key = `mapping-pricing-group-${new Date().getTime()}`;
    message.loading({ content: 'Processing...', key });
    setLoading(true);

    const body = {
      name,
      client_id: Number(clientID),
      effective_date: startDate ? startDate.format('YYYY-MM-DDT00:00:00') + 'Z' : null,
      end_date: endDate ? endDate.format('YYYY-MM-DDT23:59:59') + 'Z' : null,
      is_all_zones: isAllZones,
      zones: !isAllZones
        ? rules.map((rule) => ({ origin: rule.origin, destination: rule.destination }))
        : []
    };

    if (!body.name || !body.effective_date || (!body.is_all_zones && !body.zones.length)) {
      setLoading(false);
      message.error({ content: 'Make sure you fill out the details', key });
      return;
    }

    clientService
      .mappingClientPricingGroup(body)
      .then(() => {
        setLoading(false);
        setMappingVisible(false);
        message.success({ content: 'Successfully mapping the pricing group!', key });
        dispatch(getClientDetail(clientID));
      })
      .catch((err) => {
        setLoading(false);
        message.error({ content: err.result || 'Failed to process', key });
        console.error(err);
      });
  };

  const handleExpiring = (pricingGroup) => (date) => {
    if (!date) {
      return;
    }

    const endDate = date.utc().endOf('day').format('DD MMM YYYY');

    confirm({
      width: 720,
      icon: false,
      okText: 'Confirm',
      afterClose,
      content: (
        <StyledModal>
          <Row gutter={[24, 16]}>
            <Col span={24}>
              <span className="value">
                Are you sure want to expire the pricing group mapping from the <b>{endDate}</b>?
              </span>
            </Col>
            <Col span={6}>
              <span className="label">Pricing Group</span>
            </Col>
            <Col span={18}>
              <span className="value">{pricingGroup.name}</span>
            </Col>
            <Col span={6}>
              <span className="label">Origin Zone</span>
            </Col>
            <Col span={18}>
              <span className="value">{pricingGroup.origin_zone || '-'}</span>
            </Col>
            <Col span={6}>
              <span className="label">Destination Zone</span>
            </Col>
            <Col span={18}>
              <span className="value">{pricingGroup.destination_zone || '-'}</span>
            </Col>
            <Col span={6}>
              <span className="label">Start Date</span>
            </Col>
            <Col span={18}>
              <span className="value">
                {pricingGroup.effective_date
                  ? moment.utc(pricingGroup.effective_date).format('DD MMM YYYY')
                  : '-'}
              </span>
            </Col>
            <Col span={6}>
              <span className="label">End Date</span>
            </Col>
            <Col span={18}>
              <span className="value">{endDate}</span>
            </Col>
          </Row>
        </StyledModal>
      ),
      onOk: () => {
        const key = `expiring-pricing-group-${new Date().getTime()}`;
        message.loading({ content: 'Processing...', key });
        setLoading(true);

        const body = {
          end_date: date.format('YYYY-MM-DDT23:59:59') + 'Z'
        };

        clientService
          .expiringClientPricingGroup(pricingGroup.id, body)
          .then(() => {
            setLoading(false);
            message.success({ content: 'Successfully set the end date!', key });
            dispatch(getClientDetail(clientID));
          })
          .catch((err) => {
            setLoading(false);
            message.error({ content: err.result || 'Failed to process', key });
            console.error(err);
          });
      }
    });
  };

  const handleBulkExpiring = () => {
    const key = `bulk-expiring-pricing-group-${new Date().getTime()}`;
    message.loading({ content: 'Processing...', key });
    setLoading(true);

    if (!endDate) {
      setLoading(false);
      message.error({ content: 'Please set the end date', key });
      return;
    }

    const body = {
      end_date: endDate.format('YYYY-MM-DDT23:59:59') + 'Z'
    };

    const promises = [];

    expiringPricingGroups.forEach((pg) => {
      promises.push(clientService.expiringClientPricingGroup(pg.id, body));
    });

    const result = Promise.all(promises);
    result
      .then(() => {
        setLoading(false);
        message.success({ content: 'Successfully set the end date!', key });
        dispatch(getClientDetail(clientID));
      })
      .catch((err) => {
        setLoading(false);
        message.error({ content: err.result || 'Failed to process', key });
        console.error(err);
      });
  };

  const displayEndDate = (item) => {
    if (!isAdmin) {
      return item.end_date ? moment.utc(item.end_date).format('DD MMM YYYY') : '-';
    }

    if (!item.end_date) {
      return (
        <StyledDatePicker
          id="expiring-date"
          format="DD MMM YYYY"
          placeholder="-"
          bordered={false}
          disabled={loading}
          value={expiringDate}
          onChange={handleExpiring(item)}
          disabledDate={handleDisabledDate(
            item.effective_date ? moment.utc(item.effective_date) : disabledDate,
            null
          )}
        />
      );
    }

    if (moment.utc(item.end_date) > moment()) {
      return (
        <StyledDatePicker
          id="expiring-date"
          format="DD MMM YYYY"
          bordered={false}
          allowClear={false}
          disabled={loading}
          value={moment.utc(item.end_date)}
          onChange={handleExpiring(item)}
          disabledDate={handleDisabledDate(
            item.effective_date ? moment.utc(item.effective_date) : disabledDate,
            null
          )}
        />
      );
    }

    return moment.utc(item.end_date).format('DD MMM YYYY');
  };

  const checkExpired = (endDate) => {
    if (!endDate || moment.utc(endDate) > moment()) {
      return false;
    }

    return true;
  };

  const handleCancel = () => {
    setMappingVisible(false);
    setExpiringVisible(false);
  };

  const afterClose = () => {
    setState(initialState);
    setDisabledDate(moment().subtract(1, 'days'));
  };

  useEffect(() => {
    if (mappedCustomPricingGroups.length) {
      setPricingGroups(mappedCustomPricingGroups.slice(0, 5));
    }
  }, [mappedCustomPricingGroups]);

  useEffect(() => {
    if (viewAllPricingGroups) {
      setPricingGroups(mappedCustomPricingGroups);
    } else {
      setPricingGroups(mappedCustomPricingGroups.slice(0, 5));
    }
  }, [viewAllPricingGroups]);

  useEffect(() => {
    if (pricingGroupZonesLoading) {
      setState((prevState) => ({
        ...prevState,
        origin: undefined,
        destination: undefined,
        origins: [],
        destinations: []
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        origins: pricingGroupOrigins,
        destinations: pricingGroupDestinations
      }));
    }
  }, [dispatch, pricingGroupZonesLoading]);

  const getPricingGroups = (txt) => {
    dispatch(getPricingGroupsWithEffectiveDate(txt));
  };

  return (
    <>
      <Modal
        title="Map Custom Pricing Group"
        width={820}
        visible={mappingVisible}
        onOk={handleMapping}
        onCancel={handleCancel}
        destroyOnClose={true}
        afterClose={afterClose}
        footer={[
          <Button key="back" type="secondary" disabled={loading} onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" loading={loading} onClick={handleMapping}>
            Add
          </Button>
        ]}
      >
        <StyledModal>
          <Row gutter={[24, 16]}>
            <Col span={24}>
              <span className="label">Pricing Group</span>
              <AutoComplete
                allowClear
                loading={pricingGroupLoading}
                options={data?.map((pg, i) => ({
                  value: JSON.stringify(pg),
                  key: i,
                  label: (
                    <span>
                      {pg.name} &nbsp;
                      <span style={{ fontWeight: 200, color: '#707070' }}>
                        {`(${formatDateDisplay(pg.effective_date).format('DD MMM YYYY')})`}
                      </span>
                    </span>
                  )
                }))}
                placeholder="Type here"
                onSelect={handleValue('name')}
                value={name}
                onSearch={(searchText) => {
                  getPricingGroups(searchText);
                }}
                onClear={() => {
                  setState(initialState);
                }}
              />
            </Col>
            <Col span={12}>
              <span className="label">Start Date</span>
              <DatePicker
                id="start_date"
                value={startDate}
                allowClear={false}
                onChange={handleValue('startDate')}
                disabled={!name}
                disabledDate={handleDisabledDate(disabledDate, null)}
              />
            </Col>

            <Col span={12}>
              <span className="label">
                End Date <span className="info">(Optional)</span>
              </span>
              <DatePicker
                id="end_date"
                value={endDate}
                showToday={false}
                onChange={handleValue('endDate')}
                disabled={!startDate}
                disabledDate={handleDisabledDate(startDate, null)}
              />
            </Col>

            {!isAllZones ? (
              <>
                <Col span={9}>
                  <span className="label">Origin Zone</span>
                  <SearchInput
                    allowClear
                    id="origin"
                    placeholder="Type or select..."
                    loading={pricingGroupZonesLoading}
                    disabled={pricingGroupZonesLoading}
                    items={origins.map((origin, i) => {
                      return { value: origin, key: i, label: origin };
                    })}
                    value={origin}
                    onChange={handleValue('origin')}
                  />
                </Col>

                <Col span={9}>
                  <span className="label">Destination Zone</span>
                  <SearchInput
                    allowClear
                    id="destination"
                    placeholder="Type or select..."
                    loading={pricingGroupZonesLoading}
                    disabled={pricingGroupZonesLoading}
                    items={destinations.map((destination, i) => {
                      return { value: destination, key: i, label: destination };
                    })}
                    value={destination}
                    onChange={handleValue('destination')}
                  />
                </Col>

                <Col span={1}>
                  <span className="label"></span>
                  <PlusSquareTwoTone
                    twoToneColor="#5050f9"
                    style={{ cursor: 'pointer', fontSize: '30px', marginTop: '26px' }}
                    onClick={() => handleAddRule()}
                  />
                </Col>

                <Col span={3} offset={2}>
                  <span className="label">All 🠮 All</span>
                  <Switch
                    style={{ marginTop: '5px' }}
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    checked={isAllZones}
                    onChange={handleValue('isAllZones')}
                  />
                </Col>

                <Col span={24}>
                  <span className="label">Lanes</span>
                  <List
                    bordered
                    size="small"
                    disabled
                    dataSource={rules}
                    locale={{ emptyText: <span>Please add some lane(s)</span> }}
                    header={
                      <Row gutter={[24, 16]}>
                        <Col span={11}>
                          <span className="label">Origin Zones</span>
                        </Col>
                        <Col span={11}>
                          <span className="label">Destination Zones</span>
                        </Col>
                        <Col span={2}>
                          <span className="label"></span>
                        </Col>
                      </Row>
                    }
                    renderItem={(item) => (
                      <Row gutter={[24, 16]} style={{ padding: '8px 15px' }}>
                        <Col span={11}>
                          <span className="value">{item.origin}</span>
                        </Col>
                        <Col span={11}>
                          <span className="value">{item.destination}</span>
                        </Col>
                        <Col span={2}>
                          <DeleteTwoTone
                            twoToneColor="red"
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleDeleteRule(item.key)}
                          />
                        </Col>
                      </Row>
                    )}
                  />
                </Col>
              </>
            ) : (
              <Col span={24} offset={21}>
                <span className="label">All 🠮 All</span>
                <Switch
                  style={{ marginTop: '5px' }}
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  checked={isAllZones}
                  onChange={handleValue('isAllZones')}
                />
              </Col>
            )}
          </Row>
        </StyledModal>
      </Modal>

      <Modal
        title="Expiring Pricing Groups"
        width={1020}
        visible={expiringVisible}
        onOk={handleBulkExpiring}
        onCancel={handleCancel}
        destroyOnClose={true}
        afterClose={afterClose}
        footer={[
          <Button key="back" type="secondary" disabled={loading} onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" loading={loading} onClick={handleBulkExpiring}>
            Confirm
          </Button>
        ]}
      >
        <StyledModal>
          <List
            bordered
            size="small"
            dataSource={expiringPricingGroups}
            header={
              <Row gutter={[24, 16]}>
                <Col span={6}>
                  <span className="header">Name</span>
                </Col>
                <Col span={5}>
                  <span className="header">Origin Zones</span>
                </Col>
                <Col span={5}>
                  <span className="header">Destination Zones</span>
                </Col>
                <Col span={4}>
                  <span className="header">Start Date</span>
                </Col>
                <Col span={4}>
                  <span className="header">End Date</span>
                </Col>
              </Row>
            }
            renderItem={(item) => (
              <Row gutter={[24, 16]} style={{ padding: '8px 15px' }}>
                <Col span={6}>
                  <span className="value">
                    <ActionLink to={`/pricing-groups/${encodeURIComponent(item.name)}`}>
                      {item.name}
                    </ActionLink>
                  </span>
                </Col>
                <Col span={5}>
                  <span className="value">{item.origin_zone || '-'}</span>
                </Col>
                <Col span={5}>
                  <span className="value">{item.destination_zone || '-'}</span>
                </Col>
                <Col span={4}>
                  <span className="value">
                    {item.effective_date
                      ? moment.utc(item.effective_date).format('DD MMM YYYY')
                      : '-'}
                  </span>
                </Col>
                <Col span={4}>
                  <span className="value">
                    {item.end_date ? moment.utc(item.end_date).format('DD MMM YYYY') : '-'}
                  </span>
                </Col>
              </Row>
            )}
          />

          <Divider />

          <span className="label">End Date</span>
          <DatePicker
            id="end_date"
            value={endDate}
            showToday={false}
            onChange={handleValue('endDate')}
            disabledDate={handleDisabledDate(disabledDate, null)}
          />
        </StyledModal>
      </Modal>

      <Row gutter={[24, 16]}>
        <Col span={24}>
          <StyledHeader>
            Mapped Custom Pricing Group &nbsp;{' '}
            {isAdmin && (
              <>
                <Button
                  type="link"
                  icon={<PlusCircleTwoTone twoToneColor="#5050f9" />}
                  onClick={() => setMappingVisible(true)}
                />
                <Button
                  type="secondary"
                  style={{ marginLeft: 'auto' }}
                  disabled={!expiringPricingGroups.length}
                  onClick={() => setExpiringVisible(true)}
                >
                  Set End Date
                </Button>
              </>
            )}
          </StyledHeader>

          <div>
            <List
              header={
                <Row gutter={[24, 16]}>
                  {isAdmin && <Col span={1} />}
                  <Col span={isAdmin ? 5 : 6}>
                    <span className="header">Name</span>
                  </Col>
                  <Col span={5}>
                    <span className="header">Origin Zones</span>
                  </Col>
                  <Col span={5}>
                    <span className="header">Destination Zones</span>
                  </Col>
                  <Col span={4}>
                    <span className="header">Start Date</span>
                  </Col>
                  <Col span={4}>
                    <span className="header">End Date</span>
                  </Col>
                </Row>
              }
              footer={
                (pricingGroups.length !== mappedCustomPricingGroups.length ||
                  pricingGroups.length > 5) && (
                  <div style={{ textAlign: 'center' }}>
                    <>
                      {!viewAllPricingGroups && (
                        <span className="view-link" onClick={() => setViewAllPricingGroups(true)}>
                          View All
                        </span>
                      )}
                      {viewAllPricingGroups && (
                        <span className="view-link" onClick={() => setViewAllPricingGroups(false)}>
                          View Less
                        </span>
                      )}
                    </>
                  </div>
                )
              }
              bordered
              size="small"
              dataSource={pricingGroups}
              renderItem={(item) => (
                <Row gutter={[24, 16]} style={{ padding: '8px 15px' }}>
                  {isAdmin && (
                    <Col span={1}>
                      <span>
                        <Checkbox
                          disabled={checkExpired(item.end_date)}
                          onChange={handleCheckbox(item)}
                        />
                      </span>
                    </Col>
                  )}
                  <Col span={isAdmin ? 5 : 6}>
                    <span className={checkExpired(item.end_date) ? 'value expired' : 'value'}>
                      <ActionLink to={`/pricing-groups/${encodeURIComponent(item.name)}`}>
                        {item.name}
                      </ActionLink>
                    </span>
                  </Col>
                  <Col span={5}>
                    <span className={checkExpired(item.end_date) ? 'value expired' : 'value'}>
                      {item.origin_zone || '-'}
                    </span>
                  </Col>
                  <Col span={5}>
                    <span className={checkExpired(item.end_date) ? 'value expired' : 'value'}>
                      {item.destination_zone || '-'}
                    </span>
                  </Col>
                  <Col span={4}>
                    <span className={checkExpired(item.end_date) ? 'value expired' : 'value'}>
                      {item.effective_date
                        ? moment.utc(item.effective_date).format('DD MMM YYYY')
                        : '-'}
                    </span>
                  </Col>
                  <Col span={4}>
                    <span className={checkExpired(item.end_date) ? 'value expired' : 'value'}>
                      {displayEndDate(item)}
                    </span>
                  </Col>
                </Row>
              )}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default withRouter(MappedCustomPricingGroups);
